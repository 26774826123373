<button mat-icon-button aria-label="close dialog" mat-dialog-close class="close">
    <mat-icon>close</mat-icon>
</button>

<i>!</i>
<h3>Información sobre saldo disponible para operar</h3>
<div class="balance-container">
    <div class="saldo-container">
        Saldo cta cte:
        <div>
            {{ data.saldoCtaCte }} kg
        </div>
    </div>
    <div class="saldo-container">
        Saldo comprometido:
        <div>
            {{ data.saldoComprometido }} kg
        </div>
    </div>
    <div class="available-container">
        Saldo disponible:
        <div>
            {{ data.saldoDisponible }} kg
        </div>
    </div>
</div>
<agd-alert-box>
    El saldo comprometido refleja los kg involucrados en negocios que esperan ser aprobados.
</agd-alert-box>
