<h3>{{ data.title }}</h3>
<agd-alert-box class="warning" *ngIf="data.warningMsg">
    {{ data.warningMsg }}
</agd-alert-box>
<div class="prices-container">
    <div class="old-price-container">
        {{ data.oldValue.label }}
        <div>
            <span class="old-price" [agdCurrencyFormat]="data.oldValue.value"></span>
            <span>{{ data.oldValue.unit }}</span>
        </div>
    </div>
    <mat-icon svgIcon="down_arrow"></mat-icon>
    <div class="new-price-container">
        {{ data.newValue.label }}
        <div>
            <span class="new-price" [agdCurrencyFormat]="data.newValue.value"></span>
            <span>{{ data.newValue.unit }}</span>
        </div>
    </div>
</div>
<ng-container *ngIf="data.showSimulation">
    <p>El cambio produjo una modificación en el cálculo del monto a recibir</p>

    <h4>Recibirás aproximadamente...</h4>

    <div class="balance-container">
        <agd-balance-simulation class="left-currency" [balance]="{ precio: data.showSimulation.price }"
            [kgs]="data.showSimulation.kgs">
        </agd-balance-simulation>

        <agd-seuo-disclaimer-button [monedaId]="data.showSimulation.price.moneda.id"></agd-seuo-disclaimer-button>
    </div>
</ng-container>
<p class="hint" *ngIf="data.hint">{{ data.hint }}</p>
<div class="confirmation-btn">
    <ng-container *ngIf="!data.customConfirmation">
        <button class="cancel" mat-dialog-close>Cancelar</button>
        <button class="confirm" [mat-dialog-close]="true">Aceptar</button>
    </ng-container>

    <ng-container *ngIf="data.customConfirmation">
        <button class="secondary" [mat-dialog-close]="data.customConfirmation.secondary.value">
            {{ data.customConfirmation.secondary.label }}
        </button>
        <button class="primary" [mat-dialog-close]="data.customConfirmation.primary.value">
            {{ data.customConfirmation.primary.label }}
        </button>
    </ng-container>
</div>