import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../../http/api.service';
import {
  getToken, Messaging, onMessage, Unsubscribe
} from '@angular/fire/messaging';
import { SubSink } from 'subsink';

const STORED_TOKEN_KEY = 'firebaseToken';

declare const window: Window & {
  webkit?: any;
};

@Injectable({
  providedIn: 'root'
})
export class FirebaseMessagingService {
  pushNotificationAllowed: boolean;

  readonly pushNotificationSupported: boolean;

  private messagesUnsubscribe: Unsubscribe;

  private registration: ServiceWorkerRegistration;

  private readonly currentMessage = new BehaviorSubject(null);

  private readonly messaging = inject(Messaging);

  private readonly subs = new SubSink();

  private readonly iOSPushCapability: boolean;

  constructor(private http: ApiService) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js', { type: 'module' }).then(registration => {
      this.registration = registration;
    });
    this.initIOSHandlers();

    this.iOSPushCapability = window.webkit?.messageHandlers?.['push-permission-request']
      && window.webkit?.messageHandlers?.['push-permission-state'];

    this.pushNotificationSupported = this.isSupported();
  }

  /**
   * Suscribirse a nuevas notificaciones
   * @returns Observable con el valor de la ultima notificacion recibida y nuevos valores
   */
  onNewFirebaseMessage() {
    return this.currentMessage.asObservable();
  }

  /**
   * Se comienza a escuchar notificaciones push en este dispositivo y se registra el token en el BE, si el permiso para las notificaciones
   * no esta establecido se pedira permiso al usuario.
   */
  async suscribeToMessages(): Promise<boolean | Error> {
    if (this.pushNotificationSupported) {
      if (this.iOSPushCapability) {
        this.pushPermissionRequest();
        return true;
      }

      return Notification.requestPermission().then((permission) => {
        this.pushNotificationAllowed = permission === 'granted';

        return getToken(this.messaging, {
          serviceWorkerRegistration: this.registration,
        }).then(this.newTokenHandler.bind(this), () => {
          localStorage.removeItem(STORED_TOKEN_KEY);
          this.pushNotificationAllowed = false;
          return this.pushNotificationAllowed;
        });
      }, () => {
        this.pushNotificationAllowed = false;
        return this.pushNotificationAllowed;
      });
    }
    return new Error('Push notifications not supported on this device');
  }

  /**
   * Dejar de escuchar notificaciones para el token actual y eliminarlo del localStorage
   */
  unsuscribeToMessages() {
    if (this.iOSPushCapability) {
      window.removeEventListener('push-notification', this.newNotificationHandler.bind(this));
    }

    if (this.messagesUnsubscribe) {
      this.messagesUnsubscribe();
      this.messagesUnsubscribe = null;
    }

    localStorage.removeItem(STORED_TOKEN_KEY);
  }

  getToken() {
    return localStorage.getItem(STORED_TOKEN_KEY);
  }

  private registerFirebaseToken(fcmToken: string) {
    const body = {
      fcmToken,
      isMobile: false
    };

    return this.http.post('usuarios/verificar-dispositivo', body);
  }

  private initIOSHandlers() {
    if (this.iOSPushCapability) {
      window.addEventListener('push-permission-request', (event: CustomEvent) => {
        const granted = event?.detail === 'granted';

        if (granted) {
          this.pushTokenRequest();
        }

        this.pushNotificationAllowed = granted;
      });

      window.addEventListener('push-token', (event: CustomEvent) => {
        if (event?.detail) {
          this.newTokenHandler(event.detail);
        }
      });
    }
  }

  private newTokenHandler(token: string) {
    localStorage.setItem(STORED_TOKEN_KEY, token);

    this.subs.sink = this.registerFirebaseToken(token).subscribe();

    if (this.iOSPushCapability) {
      window.addEventListener('push-notification', this.newNotificationHandler.bind(this));
    } else {
      this.messagesUnsubscribe = onMessage(this.messaging, (notification) => {
        this.currentMessage.next(notification);
      });
    }

    return this.pushNotificationAllowed;
  }

  // iOS exclusive behaviour methods
  private pushPermissionRequest() {
    window.webkit.messageHandlers['push-permission-request'].postMessage('push-permission-request');
  }

  private pushTokenRequest() {
    window.webkit.messageHandlers['push-token'].postMessage('push-token');
  }

  private newNotificationHandler(event: CustomEvent) {
    if (event?.detail) {
      this.currentMessage.next(event.detail);
    }
  }

  private isSupported() {
    return this.iOSPushCapability || ('Notification' in window
      && 'serviceWorker' in navigator
      && 'PushManager' in window);
  }
}
