import { Component } from '@angular/core';

@Component({
  selector: 'agd-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss']
  })
export class AlertBoxComponent {

}
