import { Notification } from '../../models/notification.model';
import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NotificationsService } from '../../services/notifications/notifications.service';

interface SnackbarData {
  notification: Notification;
  userId: string;
}
@Component({
  selector: 'app-notification-snackbar',
  templateUrl: './notification-snackbar.component.html',
  styleUrls: ['./notification-snackbar.component.scss']
})
export class NotificationSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData,
    private snackBarRef: MatSnackBarRef<NotificationSnackbarComponent>,
    private notificationsService: NotificationsService
  ) { }

  /**
   * Goes to destination and close snackbar.
   */
  goToDestination() {
    const { notification, userId } = this.data;

    this.notificationsService.handleNotification(notification, userId);
    this.snackBarRef.dismiss();
  }
}
