import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { routeDictionary } from 'src/app/_core/dictionary/routes.dictionary';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../_core/http/api.service';
import { HttpParams } from '@angular/common/http';
import { ModulesPermissions, GridsPermissions } from '../../../../../helpers/applicationConstants';
import { ReplaySubject } from 'rxjs';
import { Notification } from '../../models/notification.model';
import { ConfirmLinkComponent } from 'src/app/_shared/components/confirm-link/confirm-link.component';

interface GridNotifications {
  name: string;
  notifications: number;
}

interface ModuleNotifications {
  name: string;
  notifications: number;
  gridNotifications: GridNotifications[];
}

interface ModuleNotificationEntry {
  [module: string]: ModuleNotifications;
}

@Injectable({
  providedIn: 'root'
  })
export class NotificationsService {
  showModalSubject: ReplaySubject<Notification> = new ReplaySubject(1);

  private modulesNotifications = {};

  constructor(
    private http: ApiService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.resetGridsNotifications();
  }

  /**
   * Gets notifications associated with current user and cuit.
   * @param cuit cuit
   * @param page page
   * @param count count
   */
  public getNotifications(cuit: string, page: number, count: number) {
    const params = new HttpParams()
      .set('pagina', page.toString())
      .set('cantidad', count.toString());

    return this.http.get(`notificaciones/${cuit}`, params).pipe(map((data: any) => {
      const notificationData = { ...data };

      if (data.notificaciones) {
        notificationData.notificaciones = notificationData.notificaciones.map(notif => new Notification(notif));
      }

      return notificationData;
    }));
  }

  /**
   * Gets unseen notifications count associated with current user and cuit.
   * @param cuit cuit
   */
  public getNotificationsCount(cuit: string) {
    const params = new HttpParams()
      .set('visto', 'false');

    return this.http.get(`notificaciones/${cuit}`, params);
  }

  /**
   * Gets user's configuration related to his notifications.
   * @param userId userId
   */
  public getConfigurationData() {
    const params = new HttpParams();
    return this.http.get('usuarios/autogestion/notificaciones', params);
  }

  /**
   * Saves user's configuration related to his notifications.
   * @param body request body
   */
  public saveConfiguration(body: any) {
    return this.http.put('usuarios/autogestion/notificaciones', body);
  }

  /**
   * Calls service in order to mark as seen notifications based on parameters.
   * @param userId userId
   * @param notification notification data
   */
  public markAsSeen(userId, notification: Notification) {
    // if the user has not seen it yet, call service in order to mark as seen.
    if (!notification.visto) {
      const { modulo_id: moduloId, grilla_id: grillaId, campaniaId } = notification;

      const body = campaniaId ? { campaniaId } : { moduloId, grillaId };

      return this.http.put(`notificaciones/marcar-como-vista/${userId}`, body);
    }

    return null;
  }

  /**
   * Adds notification to module & grid count.
   * @param modulo_id modulo
   * @param grilla_id grilla
   */
  public updateModuleNotifications(modulo_id: any, grilla_id: any) {
    if (!this.modulesNotifications[modulo_id]) return;

    this.modulesNotifications[modulo_id].notifications += 1;
    const grillaIndex = this.modulesNotifications[modulo_id].gridNotifications.findIndex((arrayItem => arrayItem.name === grilla_id));
    this.modulesNotifications[modulo_id].gridNotifications[grillaIndex].notifications += 1;
  }

  /**
   * Sets grids notifications.
   * @param permissions cuit permissions
   */
  public setGridsNotifications(permissions: any) {
    if (!permissions) {
      return;
    }
    this.resetGridsNotifications();
    const modules = Object.keys(this.modulesNotifications);
    modules.forEach((module) => {
      for (let i = 0; i < permissions.length; i++) {
        // Checks if module has notifications and also sets notifications for each grid.
        if (permissions[i].moduloId === this.modulesNotifications[module].name
          && permissions[i].notificaciones) {
          permissions[i].notificaciones.forEach(grid => {
            // Increase module notifications.
            this.modulesNotifications[module].notifications += grid.cantidad;
            this.modulesNotifications[module].gridNotifications.forEach(gridNotification => {
              // Increase grid notifications.
              if (gridNotification.name === grid.idGrilla && grid.cantidad > 0) {
                const gridNotif = gridNotification;
                gridNotif.notifications = grid.cantidad;
              }
            });
          });
        }
      }
    });
  }

  /**
   * Resets grids notifications.
   * We only consider modules that could have notifications.
   */
  public resetGridsNotifications() {
    this.modulesNotifications = {
      ...this.initModuleObj(ModulesPermissions.EXPERTA, [GridsPermissions.INFORMES]),
      ...this.initModuleObj(ModulesPermissions.CUENTA_CORRIENTE, [GridsPermissions.ULTIMOS_MOVIMIENTOS]),
      ...this.initModuleObj(ModulesPermissions.PRECIOS, [GridsPermissions.PRECIOS]),
    };
  }

  /**
   * Returns true or false if module/grid have any notifications.
   * @param moduleName module name.
   * @param gridName grid name.
   */
  public checkGridNotifications(moduleName: string, gridName: string): boolean {
    if (gridName) {
      for (let i = 0; i < this.modulesNotifications[moduleName].gridNotifications.length; i++) {
        if (this.modulesNotifications[moduleName].gridNotifications[i].name === gridName
          && this.modulesNotifications[moduleName].gridNotifications[i].notifications > 0) {
          return true;
        }
      }
      return false;
    }
    return this.modulesNotifications[moduleName].notifications > 0;
  }

  /**
   * Returns notifications count (sidebar).
   * @param moduleName module name.
   * @param gridName grid name.
   */
  public getGridNotificationsCount(moduleName: string, gridName: string) {
    if (gridName) {
      for (let i = 0; i < this.modulesNotifications[moduleName].gridNotifications.length; i++) {
        if (this.modulesNotifications[moduleName].gridNotifications[i].name === gridName) {
          return this.modulesNotifications[moduleName].gridNotifications[i].notifications;
        }
      }
      return 0;
    }
    return this.modulesNotifications[moduleName].notifications;
  }

  /**
   * Hide module/grid notifications.
   * @param moduleName module name.
   * @param gridName grid name.
   */
  public hideGridNotifications(moduleName: string, gridName: string) {
    let notificationsCount = 0;
    for (let i = 0; i < this.modulesNotifications[moduleName].gridNotifications.length; i++) {
      if (this.modulesNotifications[moduleName].gridNotifications[i].name === gridName) {
        notificationsCount = this.modulesNotifications[moduleName].gridNotifications[i].notifications;
        this.modulesNotifications[moduleName].gridNotifications[i].notifications = 0;
      }
    }
    this.modulesNotifications[moduleName].notifications -= notificationsCount;
  }


  handleNotification(notification: Notification, userId?: string) {
    switch (notification.tipo) {
      case 'modal':
        this.showModalNotification(notification, userId);
        break;
      default: {
        if (notification.modulo_id) {
          const moduleData = routeDictionary[notification.modulo_id];
          const path = moduleData && moduleData[notification.grilla_id];

          if (path) {
            if (notification.associatedId) {
              this.router.navigateByUrl(`${path}/${notification.associatedId}`);
            } else {
              this.router.navigateByUrl(path);
            }
          }
        } else if (notification.url) {
          this.showLinkConfirmationModal(notification.url);
        }

        this.markAsSeen(userId, notification)?.subscribe({
          error: (err: any) => {
            console.error(err);
          }
        });

        break;
      }
    }
  }

  /**
   * Emit message to subcribers about modal notification
   * @param notification notification data.
   * @param user_id user id
   */
  public showModalNotification(notification: Notification, user_id?: string) {
    this.showModalSubject.next(notification);

    if (user_id) {
      this.markAsSeen(user_id, notification)?.subscribe({
        error: (err: any) => {
          console.error(err);
        }
      });
    }
  }

  showLinkConfirmationModal(url: string): MatDialogRef<ConfirmLinkComponent, any> {
    const dialogRef = this.dialog.open(
      ConfirmLinkComponent,
      {
        data: { url },
        id: 'link-confirmation-modal',
        panelClass: 'no-min-width'
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // remove protocol from url
        const parsedURL = url.replace(/(^\w+:|^)\/\//, '');
        window.open(`//${parsedURL}`, '_blank');
      }
    });

    return dialogRef;
  }

  /**
   * Initializes the notification configuration of a module and returns it.
   * @param moduleId the module id to configure
   * @param gridIds the list of grid ids to initialize
   * @returns an ModuleNotificationEntry object
   */
  private initModuleObj(moduleId: string, gridIds: string[]): ModuleNotificationEntry {
    // Initialize object for every grid on gridIds
    const gridNotifications: GridNotifications[] = gridIds.map(gridId => ({
      name: gridId,
      notifications: 0,
    }));

    // Initialize Notification Module
    const config: ModuleNotificationEntry = {
      [moduleId]: {
        name: moduleId,
        notifications: 0,
        gridNotifications,
      }
    };

    return config;
  }
}
