import { Injectable, Output, EventEmitter } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CuitChangedService {
  @Output() activeCuitChanged = new EventEmitter<string>();

  public dataChanged: ReplaySubject<any> = new ReplaySubject(1);

  public permissionsChanged: ReplaySubject<any> = new ReplaySubject(1);

  constructor() {
    this.activeCuitChanged = new EventEmitter();
  }

  /**
   * Informs that cuit has changed.
   * @param cuit cuit
   */
  public cuitChanged(cuit: string): void {
    this.activeCuitChanged.emit(cuit);
  }

  /**
   * Inform that permissions has changed
   * @param permissions permissions
   */
  public cuitPermissionsChanged(permissions: any, data: any): void {
    this.permissionsChanged.next(permissions);
    this.dataChanged.next(data);
  }
}
