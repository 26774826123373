type NotificationType = 'modal' | 'no-media';

interface ModalPlatformConfiguration {
  titulo: string;
  descripcion: string;
  imagenes: string[][];
  etiquetaBoton: string;
  accion?: 'navegar';
}

export class Notification {
  titulo: string;

  mensaje: string;

  fecha: Date;

  visto: boolean;

  tipo: NotificationType;

  campaniaId?: string;

  nombreModulo: string;

  iconoModulo: string;

  // Determina si tiene un redireccionamiento
  modulo_id?: string;

  grilla_id?: string;

  url?: string;

  cuit?: string | string[];

  image_url?: string;

  associatedId?: number;


  modal?: {
    web?: ModalPlatformConfiguration;
    mobile?: ModalPlatformConfiguration;
  };

  constructor(object: any) {
    this.tipo = object.tipo;
    this.titulo = object.titulo;
    this.mensaje = object.mensaje;
    this.fecha = object.fecha;
    this.visto = object.visto;
    this.campaniaId = object.campaniaId;
    this.modulo_id = object.modulo_id;
    this.grilla_id = object.grilla_id;
    this.url = object.url;
    this.image_url = object.image_url;
    this.associatedId = object.associatedId;
    this.nombreModulo = object.titulo_modulo;
    this.iconoModulo = object.nombre_icono;

    if (object.cuit) {
      try {
        const cuit = JSON.parse(object.cuit);

        if (typeof cuit === 'number') {
          this.cuit = cuit.toString();
        } else if (Array.isArray(cuit)) {
          this.cuit = cuit;
        }
      } catch {
        this.cuit = [];
      }
    }

    const { modal } = object;

    if (typeof modal !== 'string') return;

    let modalConfig;

    try {
      const config = JSON.parse(modal);

      modalConfig = {
        web: {
          titulo: config.web?.titulo,
          descripcion: config.web?.descripcion,
          imagenes: config.web?.imagenes || [],
          etiquetaBoton: config.web?.etiquetaBoton,
          accion: config.web?.accion,
        },
      };
    } finally {
      this.modal = modalConfig;
    }
  }
}
