import { Component, inject } from '@angular/core';
import { ModalsService } from '../../services/modals.service';
import { MatDialogRef } from '@angular/material/dialog';
import { UserDataService } from 'src/app/_core/authentication/user-data.service';

@Component({
  selector: 'agd-tos-dialog',
  templateUrl: './tos-dialog.component.html',
  styleUrls: ['./tos-dialog.component.scss']
})


export class TOSDialogComponent {
  aceptado = false;

  private userDataService = inject(UserDataService);

  private modalService = inject(ModalsService);

  constructor(
    private readonly ref: MatDialogRef<TOSDialogComponent>,
  ) { }

  clickRechazar() {
    const titulo = 'Al rechazar términos y condiciones la solicitud de permiso de venta de granos queda anulada.';
    const cuerpo = `Esta acción es irreversible, para solicitar el permiso de venta de granos nuevamente contáctate 
    con el acopio con el que operás habitualmente`;

    const cuitActivo = this.userDataService.activeCUIT;

    this.modalService.showConfirmationDialog(titulo, cuerpo, 'Volver atrás', 'Si, rechazar').then((valorelegidomodal) => {
      // cerrar este modal si eligio "rechazar"
      if (valorelegidomodal) {
        this.modalService.registerTOS(false, cuitActivo).subscribe(
          valor => {
            this.ref.close();
          }
        );
      }
    });
  }

  clickAceptarTOS() {
    const cuitActivo = this.userDataService.activeCUIT;
    this.modalService.registerTOS(true, cuitActivo).subscribe(
      valor => {
        this.userDataService.setActiveCUIT(cuitActivo);
        this.ref.close();
      },
    );
  }
}
