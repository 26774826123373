import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface UpdateData {
  saldoComprometido: string;
  saldoDisponible: string;
  saldoCtaCte: string;
}

@Component({
  selector: 'agd-saldo-disponible-dialog',
  templateUrl: './saldo-disponible-dialog.component.html',
  styleUrls: ['./saldo-disponible-dialog.component.scss']
  })
export class SaldoDisponibleDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UpdateData
  ) {}
}
