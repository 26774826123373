import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RazonesChangedService {
  @Output() razonesEvent = new EventEmitter<any>();

  constructor() {
    this.razonesEvent = new EventEmitter();
  }

  /**
   * Informs that razones has changed.
   * @param razones razones
   */
  public razonesChanged(razones: any): void {
    this.razonesEvent.emit(razones);
  }
}
