import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../components/info-dialog/info-dialog.component';
import { UpdateData, ValueChangeDialogComponent } from '../components/value-change-dialog/value-change-dialog.component';
import { Precio } from '../../../../models/venta-de-granos.models';
import { SaldoDisponibleDialogComponent } from '../components/saldo-disponible-dialog/saldo-disponible-dialog.component';
import { TOSDialogComponent } from '../components/tos-dialog/tos-dialog.component';
import { ApiService } from 'src/app/_core/http/api.service';


export interface InfoDialogData {
  title: string;
  body: string;
  confirmation?: boolean;
  confirmButton: string;
  cancelButton: string;
}

@Injectable({
  providedIn: 'root'
  })

export class ModalsService {
  constructor(
    private dialog: MatDialog,
    private http: ApiService,
  ) { }


  showSEUODialog() {
    const bodyHTML = `<p>El precio es un aproximado basado en el último precio publicado.<br>
                              Los precios pueden sufrir variaciones al momento de la transacción. </p>
                              <p style="color:#97aca9">El monto a obtener no contempla gastos del acopio.</p>`;
    this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Monto a obtener (S.E.U.O.)',
        body: bodyHTML
      },
      width: 'auto',
      height: 'auto',
      panelClass: ['no-min-width', 'venta-de-granos-dialog', 'seuod-dialog'],
    });
  }

  showUsdSEUODialog() {
    const bodyHTML = `<p style="color:#707070">El precio de la mercadería se fija en 
                      Dólares Estadounidenses (USD) por 
                      Tonelada.<br>
                      La suma total de dólares estadounidenses convenida como precio será convertida para su pago en 
                      los plazos acordados a pesos o a la moneda nacional de curso legal vigente en el momento de efectuar el pago, 
                      sobre la base del tipo de cambio comprador establecido por el Banco de la Nación Argentina o el que resultare 
                      vigente para la liquidación de divisas de operaciones de exportación de granos, correspondiente al día hábil 
                      cambiario anterior de la fecha de la liquidación. Del precio así calculado se deducirá la suma de USD (Dólares 
                      Estadounidenses a convenir) por tonelada en concepto de flete por mercadería descargada en la planta de recepción 
                      como COMPRADORA.</p>`;
    this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'Monto a obtener (S.E.U.O.)',
        body: bodyHTML
      },
      width: 'auto',
      height: 'auto',
      panelClass: ['no-min-width', 'venta-de-granos-dialog', 'seuod-dialog'],
    });
  }

  showNotAgripagoUserDialog() {
    const bodyHTML = `<p>No posees cuenta de AgriPago asociada.<br>
          Por favor, <b>contacta al acopio</b> para gestionarla. </p>`;

    this.dialog.open(InfoDialogComponent, {

      data: {
        title: 'Sin cuenta asociada.',
        body: bodyHTML
      },
      width: 'auto',
      height: 'auto',
      panelClass: ['no-min-width', 'not-agripago-dialog', 'venta-de-granos-dialog'],
    });
  }

  showConfirmationDialog(titleb?: string, bodyb?: string, cancelBtn?: string, confirmBtn?: string) {
    const title = titleb || 'Salir y Descartar';
    const body = bodyb || `Al cerrar se perdará el progreso <br>
           y los avances de la venta.`;
    const cancelButton = cancelBtn || 'Cancelar';
    const confirmButton = confirmBtn || 'Confirmar';

    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title,
        body,
        confirmation: true,
        cancelButton,
        confirmButton,
      },
      disableClose: true,
      width: 'auto',
      height: 'auto',
      maxWidth: 375,
      minWidth: 327,
      panelClass: ['no-min-width', 'venta-de-granos-dialog'],
    });

    return dialogRef.afterClosed().toPromise();
  }

  showNewPriceDialog(oldValue: Precio, newValue: Precio, kgs: number) {
    const data: UpdateData = {
      title: 'Variación en el precio',
      warningMsg: 'Se produjo una variación en el precio. Por favor aceptar el nuevo precio para continuar.',
      oldValue: {
        label: 'Precio anterior',
        value: oldValue.precio,
        unit: oldValue.moneda.id === 2 ? 'USD' : 'ARS'
      },
      newValue: {
        label: 'Nuevo precio',
        value: newValue.precio,
        unit: newValue.moneda.id === 2 ? 'USD' : 'ARS'
      },
      showSimulation: {
        kgs,
        price: newValue,
      }
    };

    return this.showValueChangeModal(data);
  }

  showNewBalanceDialog(oldValue: number, newValue: number, price: Precio, minValue: number) {
    const validValue = newValue >= minValue;
    const showSimulation = validValue ? {
      kgs: newValue,
      price,
    } : null;
    const hint = !validValue ? 'Actualmente no posees saldo suficiente para crear la orden. Mínimo de venta 10kg.' : null;
    const customConfirmation = !validValue ? {
      primary: { label: 'Cargar otra orden', value: 'restart' },
      secondary: { label: 'Ir al inicio', value: 'home' },
    } : null;

    const data: UpdateData = {
      title: 'Variación en el saldo',
      warningMsg: `Se produjo una variación en el saldo.${validValue ? ' Para continuar acepta la nueva definición.' : ''}`,
      oldValue: {
        label: 'Kg a vender',
        value: oldValue,
        unit: 'kgs'
      },
      newValue: {
        label: 'Saldo actual',
        value: newValue,
        unit: 'kgs'
      },
      showSimulation,
      hint,
      customConfirmation,
    };

    return this.showValueChangeModal(data);
  }

  showBalanceDetailModal(saldoDisponible: string, saldoComprometido: string, saldoCtaCte: string) {
    const dialogRef = this.dialog.open(SaldoDisponibleDialogComponent, {
      data: {
        saldoDisponible,
        saldoComprometido,
        saldoCtaCte
      },
      width: 'auto',
      height: 'auto',
      panelClass: ['dialog-width-auto', 'venta-de-granos-dialog'],
    });

    return dialogRef.afterClosed().toPromise();
  }

  showTOSDialog() {
    const dialogRef = this.dialog.open(TOSDialogComponent, {
      width: 'auto',
      height: 'auto',
      maxWidth: 557,
      disableClose: true,
      panelClass: ['no-min-width', 'venta-de-granos-dialog', 'TOS-dialog'],
    });

    return dialogRef.afterClosed().toPromise();
  }

  registerTOS(acepto: boolean, cuit: string) {
    const body = {
      cuit,
      acepto,
    };
    return this.http.post('venta-granos/tyc', body);
  }


  private showValueChangeModal(config: UpdateData) {
    const dialogRef = this.dialog.open(ValueChangeDialogComponent, {
      data: config,
      disableClose: true,
      width: 'auto',
      height: 'auto',
      panelClass: ['dialog-width-auto', 'venta-de-granos-dialog'],
    });

    return dialogRef.afterClosed().toPromise();
  }
}
