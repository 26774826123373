import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root'
})
export class GoogeTagService {
  constructor(private readonly googleTagManagerService: GoogleTagManagerService) { }

  /**
   * Enviar información a GTM relacionada al usuario que realizo un login exitoso.
   * @param userData - Objecto con informacion del usuario.
   */
  login(userData: Object) {
    this.googleTagManagerService.pushTag({
      event: 'login',
      user_info: userData
    });
  }

  /**
   * Enviar información del CUIT actual en uso del usuario a GTM.
   * @param cuitData objeto con la informacion relaaionada al CUIT.
   */
  cuitActivo(cuitData: Object) {
    this.googleTagManagerService.pushTag({
      event: 'active_cuit',
      active_cuit: cuitData
    });
  }

  /**
   * Enviar informacion a GTM de la navegacion actual del usuario.
   * @param pageName - URL actual.
   */
  registrarNavegacion(pageName: string) {
    this.googleTagManagerService.pushTag({
      event: 'page_view',
      page_location: pageName
    });
  }
}
