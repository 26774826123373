import { ModulesPermissions, GridsPermissions } from '../../_shared/helpers/applicationConstants';
/**
 * Mapping from module and grid ids to routes.
 */
export const routeDictionary = {
  resumenGlobal: '/dashboard',
  preciosAgd: {
    precios: 'precios'
  },
  experta: {
    informes: 'experta/campos',
  },
  autogestion: {
    notificaciones: 'autogestion/editar-notificaciones',
  },
  [ModulesPermissions.GRANOS]: {
    [GridsPermissions.DESCARGAS]: 'granos/descargas',
    [GridsPermissions.CUENTA_CORRIENTE_GRANARIA]: 'granos/cuenta-corriente-granaria',
    [GridsPermissions.CALIDAD]: 'granos/calidad',
    [GridsPermissions.TRANSFERENCIAS]: 'granos/transferencias',
    [GridsPermissions.KG_A_FIJAR]: 'granos/kg-a-fijar',
    [GridsPermissions.POSICION_GRANARIA]: 'granos/posicion-granaria',
    [GridsPermissions.ANALISIS_DE_CALIDAD]: 'granos/analisis-de-calidad',
  },
  [ModulesPermissions.VENTAS]: {
    [GridsPermissions.CONTRATOS]: 'ventas/contratos',
    [GridsPermissions.APLICACIONES]: 'ventas/aplicaciones',
    [GridsPermissions.CONTRATOS_A_FIJAR]: 'ventas/contratos-a-fijar',
    [GridsPermissions.ROMANEOS_CONTRATOS_A_FIJAR]: 'ventas/romaneos-de-contratos-a-fijar',
  },
  [ModulesPermissions.PAGOS]: {
    [GridsPermissions.ULTIMOS_PAGOS]: 'pagos/ultimos-pagos',
  },
  [ModulesPermissions.CUENTA_CORRIENTE]: {
    [GridsPermissions.ULTIMOS_MOVIMIENTOS]: 'cuenta-corriente/ultimos-movimientos',
  },
  [ModulesPermissions.INSUMOS]: {
    [GridsPermissions.POSICION_FISICA]: 'insumos/posicion-fisica',
    [GridsPermissions.ENTREGADOS_SIN_FACTURAR]: 'insumos/entregados-sin-facturar',
    [GridsPermissions.FACTURACION]: 'insumos/facturacion',
    [GridsPermissions.HISTORIAL_DE_COMPRAS]: 'insumos/historial-de-compras',
  },
  [ModulesPermissions.CONTRATOS]: {
    [GridsPermissions.CONTRATOS]: 'contratos/contratos',
    [GridsPermissions.APLICACIONES]: 'contratos/aplicaciones',
    [GridsPermissions.CONTRATOS_A_FIJAR]: 'contratos/contratos-a-fijar',
    [GridsPermissions.ROMANEOS_CONTRATOS_A_FIJAR]: 'contratos/romaneos-de-contratos-a-fijar',
  },
  [ModulesPermissions.COMPROBANTES]: {
    [GridsPermissions.RETENCIONES]: 'retenciones/retenciones',
    [GridsPermissions.RETENCIONES_AFIP]: 'retenciones/retenciones-afip',
  },
  [ModulesPermissions.MESA_DE_AYUDA]: {
    [GridsPermissions.DETALLE_CONSULTA]: 'mesa-de-ayuda/consulta',
  }
};
