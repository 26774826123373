<div class="confirmation-modal">
  <h4 class="text-center">Atención</h4>
  <div class="link-text m-1 mt-3 text-center">Estas por abandonar la plataforma <b>AGD Agro.</b></div>
  <div class="m-1 mb-4 text-center">¿Está seguro que desea continuar?</div>
  <div class="mt-2 row">
    <div class="col d-flex justify-content-center">
      <button type="button" class="btn btn-secondary mr-3" (click)="confirm(false)">Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="confirm(true)">Aceptar</button>
    </div>
  </div>
</div>
