import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CuitService {
  constructor(private http: ApiService) { }

  /**
   * Gets cuit permissions.
   * @param cuit cuit
   */
  public getPermissions(cuit: string) {
    const params = new HttpParams();
    return this.http.get(`cuit/cuit-info/${cuit}`, params);
  }

  /**
   * Gets cuit 'Razón social'.
   * @param cuit cuit
   */
  public getRazonSocial(cuit: string) {
    const params = new HttpParams();
    return this.http.get(`cuit/obtener-razon-social/${cuit}`, params);
  }
}
