import { MatDialog } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-confirm-link',
  templateUrl: './confirm-link.component.html',
  styleUrls: ['./confirm-link.component.scss']
})
export class ConfirmLinkComponent {
  constructor(
    private dialog: MatDialog,
  ) { }

  confirm(event: boolean) {
    const modalRef = this.dialog.getDialogById('link-confirmation-modal');

    modalRef?.close(event);
  }
}
